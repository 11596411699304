import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { useSettingsStore } from '@/stores/settings';
import { useAffiliation } from '@/composables/useAffiliation';
import { useLogin } from '@/composables/useLogin';
import { popToast } from '@/composables/useToast';
import { B_VARIANT } from '@/types/frontend/enums/bootstrapEnums';
import { translate } from '@/plugins/vue-i18n';
import { Route } from 'vue-router/types/router';

const $t = translate;

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/user/Home.vue'),
    },
    {
        path: '/products',
        name: 'ProductsAll',
        component: () => import('@/views/user/Products.vue'),
    },
    {
        path: '/products/:category',
        name: 'ProductsCategory',
        component: () => import('@/views/user/Products.vue'),
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/views/user/orders/Orders.vue'),
    },
    {
        path: '/orders/:type',
        name: 'OrdersWithType',
        component: () => import('@/views/user/orders/Orders.vue'),
    },
    {
        path: '/design/:id',
        name: 'ProductDesigner',
        component: () => import('@/views/user/design/DesignStep2.vue'),
    },
    {
        path: '/design/:id/confirm',
        name: 'ProductDesignerConfirmation',
        component: () => import('@/views/user/design/DesignStep3.vue'),
    },
    {
        path: '/account-settings',
        name: 'AccountSettings',
        component: () => import('@/views/user/AccountSettings.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            noAuth: true,
        },
        component: () => import('@/views/user/AccountLogin.vue'),
    },
    {
        path: '/my-products',
        name: 'MyProducts',
        component: () => import('@/views/user/MyProducts.vue'),
    },
    {
        path: '/design-library',
        name: 'MyDesignLibrary',
        component: () => import('@/views/user/DesignLibrary.vue'),
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: () => import('@/views/user/Invoices.vue'),
    },
    {
        path: '/reset-password/',
        name: 'ResetPassword',
        component: () => import('@/views/user/PasswordReset.vue'),
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: () => import('@/views/user/ShopOnboarding.vue'),
    },
    {
        path: '/affiliate-program',
        name: 'AffiliateProgram',
        component: () => import('@/views/user/AffiliateProgram.vue'),
    },
];

//* Implementation if Scrolling is needed after Routing
// const headerHeight = -<insert height of header>;
// const spacerScroll = -<inset wished offset from top of element>;
// const scrollBehavior = (to: Route, from: Route, savedPosition: Position | void): PositionResult | Promise<PositionResult> | undefined | null => {
//     console.log(to.hash)
//     if (to.hash) {
//         Vue.nextTick(() => {
//             let y;
//             const el = document.getElementById(to.hash.slice(1))
//             if (el) y = el.getBoundingClientRect().top + window.pageYOffset + headerHeight + spacerScroll;
//             if (y) window.scrollTo({ top: y, behavior: 'smooth' })
//         })
//     }
//
//     return null
// }

const router: VueRouter & { start?: (value: unknown) => void } = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior: (to, from, savedPosition) => scrollBehavior(to, from, savedPosition),
});

//used to delay router start until auth initialization has taken place; This prevents the Login screen from showing for a split second even though the user is logged in
const startPromise = new Promise(resolve => (router.start = resolve));

router.beforeEach(async (to, from, next) => {
    await startPromise;

    checkShopConnectStatus(to);

    const affiliation = to.query.affiliation;
    if (affiliation && typeof affiliation === 'string') {
        const { setAffiliation } = useAffiliation();
        setAffiliation(affiliation);
    }

    const { getToken, preserveBeforeLoginRoute } = useLogin();
    const token = getToken();

    const settingsStore = useSettingsStore();
    if (token && !settingsStore.isSettingsLoaded) {
        await settingsStore.getSettings();
    }

    if (!token) {
        //no token and to auth route so redirect to login
        if (to.name !== 'Login') preserveBeforeLoginRoute(to);
        if (!to.meta?.noAuth) return next({ name: 'Login', query: to.query });
    } else if (to.meta?.noAuth) {
        //for example if you route to /login manually redirect to home because of token
        return next({ name: 'Home' });
    }

    for (const domain in settingsStore.settings.shops) {
        const shop = settingsStore.settings.shops[domain];
        if (shop && !shop.onboardingFlowCompleted) {
            if (shop && to.name !== 'Onboarding') {
                settingsStore.selectedShopDomain = domain;
                return next({ name: 'Onboarding' });
            }
        }
    }

    next();
});

const checkShopConnectStatus = (to: Route) => {
    const queryParams = to.query;
    if (!queryParams.shopConnectSuccess) return;
    if (queryParams.shopConnectSuccess === 'true') {
        return popToast({
            title: `${$t('app.home.shopConnectSuccess.title')}`,
            content: `${$t('app.home.shopConnectSuccess.content')}`,
            variant: B_VARIANT.SUCCESS,
        });
    }
    if (queryParams.shopConnectSuccess === 'false') {
        if (queryParams.shopConnectError && queryParams.shopConnectError === 'alreadyConnected') {
            return popToast({
                title: `${$t('app.home.shopConnectError.title')}`,
                content: `${$t('app.home.shopConnectError.alreadyConnected')}`,
                variant: B_VARIANT.DANGER,
            });
        }
        return popToast({
            title: `${$t('app.home.shopConnectError.title')}`,
            content: `${$t('app.home.shopConnectError.content')}`,
            variant: B_VARIANT.DANGER,
        });
    }
    delete to.query.shopConnectSuccess;
    delete to.query.shopConnectError;
};

//*Routeguard for App with Usersystem
// router.beforeEach((to, from, next) => {
//
//     const isAdmin = localStorage.getItem(ADMIN_KEY) === 'true'
//     const user = localStorage.getItem(TOKEN_KEY)
//
//     //Check if Page needs auth and User is Empty --> Reroute to Login
//     if (to.meta?.auth && !user) return next({ name: 'Login' })
//
//     //Check if Page needs Admin but user is not Admin --> Reroute to Admin Login
//     if (to.meta?.admin && !isAdmin) return next({ name: 'LoginAdmin' })
//
//     //Check if Target Route is Login Admin but there is a User already logged in without Admin rights
//     if (to.name === 'LoginAdmin' && user && !isAdmin) return next({ name: 'Home', })
//
//     //Check if Target Route is Login Admin but there is a logged in User with Admin rights --> Reroute to first Admin Page
//     if ((to.name === 'LoginAdmin' || to.name === 'Login') && isAdmin) return next({ name: 'HomeAdmin' })
//
//     //Check if target Route is Login but there is a logged in User --> Reroute to first User Page
//     if (to.name === 'Login' && user && !isAdmin) return next({ name: 'Home' })
//
//     next()
// })

export default router;
