import Vue from 'vue';
import VueMeta from 'vue-meta';
import './plugins/bootstrap-vue';
import App from './App.vue';
import { pinia } from '@/plugins/pinia';
import router from '@/router';
import './theme/global.css';
import './plugins/vue-i18n';
import i18n from '@/plugins/vue-i18n';
import './theme/customTheme.scss';
import './plugins/vee-validate3';
import { useModal } from '@/composables/useModal';
import './theme/nprogress.css';
import { useShopifyOAuth } from './composables/useShopifyOAuth';
import { useLogin } from './composables/useLogin';

Vue.config.productionTip = false;
const thisVue = new Vue({
    pinia,
    router,
    i18n,
    render: h => h(App),
});

Vue.use(VueMeta);

const { checkOAuthRequest } = useShopifyOAuth();
const { onSessionInitialized } = useLogin();

onSessionInitialized(() => {
    router.start?.(null);
    checkOAuthRequest().finally(() => {
        thisVue.$mount('#app');
        useModal();
    });
});

export default thisVue;
