const categories = {
    MAN_TSHIRT: 'Unisex/Herren T-Shirts',
    MAN_HOODIES: 'Herren Hoodies',
    MAN_SWEATSHIRT: 'Herren Sweatshirts',
    MAN_LONGLEEVES: 'Herren Longleves',
    MAN_TANKTOP: 'Herren Tanktops',
    TROUSERS_UNDERWEAR: 'Hosen & Unterwäsche',
    WOMAN_TSHIRT: 'Damen T-Shirts',
    WOMAN_TANKTOP: 'Damen Tanktops',
    WOMAN_SWEATSHIRT: 'Damen Sweatshirts',
    KINDER_TSHIRT: 'Kinder T-Shirts',
    OTHER: 'Sonstiges',
    MUG: 'Tassen & Gläser',
    HOME: 'Home & Living',

    Frauen: 'Frauen',
    'Unisex / Männer': 'Unisex / Männer',
    Sonstiges: 'Sonstiges',
    Kinder: 'Kinder',
    'Home & Living': 'Home & Living',
};

export default categories;
