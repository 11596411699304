import Axios, { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import nProgress from 'nprogress';
import { useLogin } from './useLogin';
import { useSettingsStore } from '@/stores/settings';
import router from '@/router';
import { popToast } from './useToast';
import { translate } from '@/plugins/vue-i18n';
import { B_VARIANT } from '@/types/frontend/enums/bootstrapEnums';

export const API_URL = ENV.API_URL;
export const CDN_URL = ENV.CDN_URL;

const { getToken, logout } = useLogin();
const $t = translate;

nProgress.configure({ showSpinner: false });
let requestCounter = 0;

export function useApi() {
    const api: AxiosInstance = Axios.create();
    let initDone = false;

    const init = async () => {
        if (initDone) return;
        api.defaults.baseURL = API_URL;

        api.interceptors.request.use(async function (config: InternalAxiosRequestConfig & { allowDisconnectedShop?: boolean }) {
            if (!config.headers) config.headers = {} as AxiosRequestHeaders;
            config.headers['Authorization'] = 'Bearer ' + getToken();

            const settingsStore = useSettingsStore();
            if (!config.allowDisconnectedShop && settingsStore.settings.disconnectedShopDomains?.includes(settingsStore.selectedShopDomain)) {
                settingsStore.selectedShopDomain = settingsStore.settings.shopDomains[0];
            }
            config.headers['X-Shop-Domain'] = settingsStore.selectedShopDomain;

            return config;
        });

        api.interceptors.request.use(async function (config) {
            startNProgress(config.url);
            return config;
        });

        api.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                if (error.response.status === 401) {
                    await logout();
                    if (router.currentRoute.name !== 'Login') {
                        router.push({ name: 'Login' });
                        popToast({
                            title: $t('app.account.logout'),
                            content: $t('app.account.logoutMessage'),
                            variant: B_VARIANT.WARNING,
                        });
                    }
                }

                return Promise.reject(error);
            },
        );

        api.interceptors.response.use(
            function (response) {
                stopNProgress(response.config.url);
                return response;
            },
            function (error) {
                stopNProgress(error.config.url);
                return Promise.reject(error);
            },
        );

        initDone = true;
    };

    init();

    return {
        api,
    };
}

function startNProgress(route?: string) {
    if (checkExceptions(route)) return;
    if (requestCounter === 0) nProgress.start();
    requestCounter++;
}

function stopNProgress(route?: string) {
    if (checkExceptions(route)) return;
    const debounceRoutes: string[] = [];
    const isDebounceRoute = debounceRoutes.some(dbr => route?.includes(dbr));
    if (isDebounceRoute) {
        //debounce for routes where many requests are fired back-to-back to avoid progress bar flickering
        setTimeout(() => {
            requestCounter--;
            if (requestCounter <= 0) requestCounter = 0;
            if (requestCounter === 0) nProgress.done();
        }, 100);
    } else {
        requestCounter--;
        if (requestCounter <= 0) requestCounter = 0;
        if (requestCounter === 0) nProgress.done();
    }
}

function checkExceptions(route?: string) {
    const exceptions = ['premockup', 'basic_products/subCategoryKey'];
    return exceptions.some(exception => route?.includes(exception));
}
