import { SupabaseErrorCode } from '@/types/frontend/supabaseTypes';
import { createClient } from '@supabase/supabase-js';
import { useAffiliation } from './useAffiliation';
import { Language } from '@/types/frontend/enums/languageEnums';
import { useShopifyOAuth } from './useShopifyOAuth';
import { Route } from 'vue-router/types/router';
import axios from 'axios';
import { API_URL } from './useApi';
import { useSettingsStore } from '@/stores/settings';

const { checkOAuthRequest } = useShopifyOAuth();

let beforeLoginRoute: Route | undefined = undefined;
let jwt: string | undefined = undefined;
let sessionInitialized = false;
const sessionInitializdListeners: Array<() => void> = [];

const supabase = createClient(ENV.SUPABASE_URL, ENV.SUPABASE_ANON_KEY);

supabase.auth.onAuthStateChange(async (event, session) => {
    if (event === 'INITIAL_SESSION') {
        if (session) {
            jwt = session.access_token;
        }
        if (session && !session.user.user_metadata.accountCreatedAt) {
            await firstTimeApiCall(session.user.email);
        }
        if (sessionInitialized) return;
        sessionInitialized = true;
        sessionInitializdListeners.forEach(listener => listener());
    } else if (event === 'SIGNED_IN') {
        if (session) jwt = session.access_token;
        if (session && !session.user.user_metadata.accountCreatedAt) {
            await firstTimeApiCall(session.user.email);
        }
        await checkOAuthRequest();
    } else if (event === 'SIGNED_OUT') {
        jwt = undefined;
    } else if (event === 'PASSWORD_RECOVERY') {
        // handle password recovery event
    } else if (event === 'TOKEN_REFRESHED') {
        if (session) jwt = session.access_token;
    } else if (event === 'USER_UPDATED') {
        if (session) jwt = session.access_token;
    }
});

const firstTimeApiCall = async (email?: string) => {
    let retries = 0;
    while (retries < 5 && !jwt) {
        await sleep(200);
        retries++;
    }
    if (!jwt) return false;
    const result = await axios.get(API_URL + '/account', { headers: { Authorization: 'Bearer ' + jwt } }).catch(() => undefined);
    if (!result) return false;
    if (email && result.data !== email) return false;
    return true;
};

const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export function useLogin() {
    const loginUser = async (
        email: string,
        password: string,
    ): Promise<'invalid_credentials' | 'email_not_confirmed' | 'unknown_error' | 'rate_limit' | true> => {
        const data = await supabase.auth.signInWithPassword({ email, password });
        if (data.error) {
            const code = data.error.code as SupabaseErrorCode;
            if (code === 'invalid_credentials' || code === 'email_not_confirmed') {
                return code;
            } else if (code.includes('rate_limit')) {
                return 'rate_limit';
            }
            return 'unknown_error';
        }
        return true;
    };

    const registerUser = async (
        email: string,
        password: string,
        newsletterAccepted: boolean,
        language: Language,
    ): Promise<'email_exists' | 'unknown_error' | 'rate_limit' | 'weak_password' | true> => {
        const { getCurrentAffiliation, clearAffiliation } = useAffiliation();
        const affiliation = getCurrentAffiliation();

        const data = await supabase.auth.signUp({
            email,
            password,
            options: {
                emailRedirectTo: ENV.BASE_URL + 'login',
                data: {
                    agbAccepted: true,
                    newsletterAccepted: newsletterAccepted,
                    affiliation: affiliation?.id,
                    language: language,
                },
            },
        });

        if (data.error) {
            const code = data.error.code as SupabaseErrorCode;
            if (code === 'email_exists' || code === 'weak_password') {
                return code;
            } else if (code.includes('rate_limit')) {
                return 'rate_limit';
            }
            return 'unknown_error';
        }
        clearAffiliation();
        return true;
    };

    const resendRegistrationEmail = async (email: string): Promise<'unknown_error' | 'rate_limit' | true> => {
        const data = await supabase.auth.resend({
            type: 'signup',
            email: email,
            options: {
                emailRedirectTo: ENV.BASE_URL + 'login',
            },
        });
        if (data.error) {
            const code = data.error.code as SupabaseErrorCode;
            if (code.includes('rate_limit')) {
                return 'rate_limit';
            }
            return 'unknown_error';
        }
        return true;
    };

    const resetPassword = async (email: string): Promise<'unknown_error' | 'rate_limit' | true> => {
        const data = await supabase.auth.resetPasswordForEmail(email, { redirectTo: ENV.BASE_URL + 'reset-password' });
        if (data.error) {
            const code = data.error.code as SupabaseErrorCode;
            if (code.includes('rate_limit')) {
                return 'rate_limit';
            }
            return 'unknown_error';
        }
        return true;
    };

    const updatePassword = async (
        password: string,
        nonce?: string,
    ): Promise<
        'weak_password' | 'unknown_error' | 'rate_limit' | 'same_password' | 'reauthentication_needed' | 'reauthentication_not_valid' | true
    > => {
        const data = await supabase.auth.updateUser({ password: password, nonce: nonce });
        if (data.error) {
            const code = data.error.code as SupabaseErrorCode;
            if (code === 'weak_password' || code === 'same_password' || code === 'reauthentication_needed' || code === 'reauthentication_not_valid') {
                return code;
            } else if (code.includes('rate_limit')) {
                return 'rate_limit';
            }
            return 'unknown_error';
        }
        return true;
    };

    const reauthenticate = async (): Promise<'unknown_error' | 'rate_limit' | true> => {
        const data = await supabase.auth.reauthenticate();
        if (data.error) {
            const code = data.error.code as SupabaseErrorCode;
            if (code.includes('rate_limit')) {
                return 'rate_limit';
            }
            return 'unknown_error';
        }
        return true;
    };

    const updateEmail = async (email: string): Promise<'email_exists' | 'unknown_error' | 'rate_limit' | true> => {
        const data = await supabase.auth.updateUser({ email: email });
        if (data.error) {
            const code = data.error.code as SupabaseErrorCode;
            if (code === 'email_exists') {
                return code;
            } else if (code.includes('rate_limit')) {
                return 'rate_limit';
            }
            return 'unknown_error';
        }
        return true;
    };

    const logout = async (global = false) => {
        await supabase.auth.signOut({ scope: global ? 'global' : 'local' });
        useSettingsStore().clearSettings();
        jwt = undefined;
    };

    const getToken = () => {
        return jwt;
    };

    const onSessionInitialized = (callback: () => void) => {
        if (sessionInitialized) callback();
        else sessionInitializdListeners.push(callback);
    };

    const preserveBeforeLoginRoute = (route: Route) => {
        beforeLoginRoute = route;
    };

    const getBeforeLoginRoute = () => {
        const beforeLogin = { ...beforeLoginRoute };
        beforeLoginRoute = undefined;
        return beforeLogin;
    };

    return {
        loginUser,
        registerUser,
        resetPassword,
        updatePassword,
        updateEmail,
        logout,
        getToken,
        onSessionInitialized,
        preserveBeforeLoginRoute,
        getBeforeLoginRoute,
        reauthenticate,
        resendRegistrationEmail,
    };
}
