const categories = {
    MAN_TSHIRT: "Unisex/Men's T-Shirts",
    MAN_HOODIES: "Men's Hoodies",
    MAN_SWEATSHIRT: "Men's Sweatshirts",
    MAN_LONGLEEVES: "Men's Longleves",
    MAN_TANKTOP: "Men's Tank Tops",
    TROUSERS_UNDERWEAR: 'Trousers & Underwear',
    WOMAN_TSHIRT: "Women's T-Shirts",
    WOMAN_TANKTOP: "Women's Tank Tops",
    WOMAN_SWEATSHIRT: "Women's Sweatshirts",
    KINDER_TSHIRT: 'Kids T-Shirts',
    OTHER: 'Other stuff',
    MUG: 'Cups & Glasses',
    HOME: 'Home & Living',

    Frauen: 'Women',
    'Unisex / Männer': 'Unisex / Men',
    Sonstiges: 'Other stuff',
    Kinder: 'Kids',
    'Home & Living': 'Home & Living',
};

export default categories;
