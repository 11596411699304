const input = {
    text: 'Text',
    password: 'Password',
    email: 'Email',
    number: 'Number',
    url: 'Link',
    tel: 'Telephone',
    search: 'Search',
    date: 'Date',
    datetime: 'Time',
    'datetime-local': 'Local time',
    month: 'Month',
    week: 'Week',
    time: 'Time',
    range: 'Scale',
    color: 'Colour',
};

export default input;
