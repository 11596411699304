import { defineStore } from 'pinia';
import { request } from '@/api-connectors/useBasicRequests';
import { AddressType, INTEGRATION_API_PARTNER, SettingsType, ShopSettings } from '@/types/backend/settingsType';
import i18n, { useI18n } from '@/plugins/vue-i18n';
import { useProductLanguageStore } from '@/stores/languages';
import { Language, LanguageUnitMapping } from '@/types/frontend/enums/languageEnums';

interface SettingsStore {
    settings: SettingsType;
    selectedShopDomain: string;
}

const getSettingsRoute = 'settings';
const setPayoutAddressRoute = 'settings/setPayoutAddress';
const setInvoiceAddressRoute = 'settings/setInvoiceAddress';
const setReturnAddressRoute = 'settings/setReturnAddress';
const setDefaultPaymentMethodRoute = 'settings/payment';
const setDefaultAffiliatePayoutMethodRoute = 'settings/affiliatePayout';
const retryPaymentRoute = 'settings/payment/retryPayment';
const setLocalizationRoute = 'settings/setLocalization/';
const setShopLocalizationRoute = 'settings/setShopLocalization';
const setNotificationRoute = 'settings/notification';
const setStripePaymentIDRoute = 'settings/payment/update_stripe_payment_method_id';
const requestStripeSetupIntentRoute = 'settings/payment/requestStripeSetupIntent';
const setOnboardingFlowCompletedRoute = 'settings/setOnboardingFlowCompleted/';
const setNewsletterAcceptedRoute = 'settings/setNewsletterAccepted/';
const generateApiTokenRoute = 'settings/apiKey';
const updateIntegrationApiInfosRoute = 'settings/integrationApiInfos';
const autoAvailabilityUpdateRoute = 'settings/autoAvailabilityUpdate';
const disconnectShopRoute = '/settings/setDisconnected';

export const useSettingsStore = defineStore('settings', {
    state: (): SettingsStore => {
        return {
            settings: {} as SettingsType,
            selectedShopDomain: '',
        };
    },
    actions: {
        async clearSettings() {
            this.settings.shopDomains = [];
            this.selectedShopDomain = '';
        },
        async getSettings(): Promise<boolean> {
            const response = await request<SettingsType>({
                method: 'get',
                route: getSettingsRoute,
            });
            if (!response) return false;
            this.initializeIntegrationApiInfos(response);
            this.settings = { ...response };

            //update language of browser
            if (!this.settings.preferredLanguage) {
                let language = i18n.locale as Language;
                if (!Object.values(Language).includes(language)) language = Language.EN;
                this.settings.preferredLanguage = language;
            }
            await this.setLocalizationDefaults(this.settings.preferredLanguage);
            const langStore = useProductLanguageStore();
            const { changeLanguage } = useI18n();
            langStore.setLocale(this.settings.preferredLanguage, this.settings.preferredUnitSystem, this.settings.preferredShoeSizeSystem);
            i18n.locale = this.settings.preferredLanguage;
            sessionStorage.setItem('locale', i18n.locale);
            changeLanguage(i18n.locale);
            if (this.settings.shopDomains.length > 0) this.selectedShopDomain = this.settings.shopDomains[0];
            return true;
        },
        initializeIntegrationApiInfos(response: SettingsType) {
            for (const key of Object.values(INTEGRATION_API_PARTNER)) {
                for (const shop of Object.values(response.shops)) {
                    if (!shop.integrationApiInfos[key]) {
                        shop.integrationApiInfos[key] = { webhookUrl: '' };
                    }
                }
            }
        },
        async setLocalizationDefaults(language: Language) {
            //set default values if not set
            if (!this.settings.preferredShoeSizeSystem || !this.settings.preferredUnitSystem) {
                const systems = LanguageUnitMapping[language];
                this.settings.preferredLanguage = language;
                this.settings.preferredShoeSizeSystem = this.settings.preferredShoeSizeSystem ?? systems.shoeSize;
                this.settings.preferredUnitSystem = this.settings.preferredUnitSystem ?? systems.unit;
                await this.setLocalization();
            }
            for (const shop of Object.values(this.settings.shops)) {
                await this.setShopLocalizationDefaults(shop);
            }
        },
        async setShopLocalizationDefaults(shop?: ShopSettings) {
            //set default values if not set
            if (!shop) shop = this.selectedShop;
            if (!shop) return;
            if (!shop.productLanguage || !shop.productUnitSystem || !shop.productShoeSizeSystem) {
                const language = shop.productLanguage ?? this.settings.preferredLanguage ?? Language.EN;
                const systems = LanguageUnitMapping[language];
                shop.productLanguage = language;
                shop.productUnitSystem = shop.productUnitSystem ?? this.settings.preferredUnitSystem ?? systems.unit;
                shop.productShoeSizeSystem = shop.productShoeSizeSystem ?? this.settings.preferredShoeSizeSystem ?? systems.shoeSize;
                await this.setShopLocalization(shop.shop_domain);
            }
        },
        getUserFullName(): string | undefined {
            if (this.settings.name) return this.settings.name;
            if (this.selectedShop?.shop_address) return this.selectedShop.shop_address.split('<br>')[0].trim();
            if (this.selectedShop?.shop_owner) return this.selectedShop.shop_owner;
        },
        async setPayoutAddress(address: AddressType & { vatId?: string }): Promise<string | undefined> {
            const response = await request<string>({
                method: 'put',
                route: setPayoutAddressRoute,
                body: {
                    payoutAddress: {
                        shop_owner: address.shop_owner,
                        street1: address.address1,
                        street1_street: address.address1_street,
                        street1_number: address.address1_number,
                        street2: '',
                        city: address.city,
                        zip: address.zip,
                        country: address.country_name,
                        countryCode: address.countryCode,
                    },
                    vatId: address.vatId,
                },
            });
            return response;
        },
        async setInvoiceAddress(address: AddressType & { vatId?: string }): Promise<string | undefined> {
            const response = await request<string>({
                method: 'put',
                route: setInvoiceAddressRoute,
                body: {
                    invoiceAddress: {
                        shop_owner: address.shop_owner,
                        street1: address.address1,
                        street1_street: address.address1_street,
                        street1_number: address.address1_number,
                        street2: '',
                        city: address.city,
                        zip: address.zip,
                        country: address.country_name,
                        countryCode: address.countryCode,
                    },
                    vatId: address.vatId,
                },
            });
            return response;
        },
        async setReturnAddress(address: AddressType): Promise<void> {
            if (!this.selectedShopDomain) return;
            const response = await request<string>({
                method: 'put',
                route: setReturnAddressRoute,
                body: {
                    returnAddress: {
                        shop_owner: address.shop_owner,
                        street1: address.address1,
                        street1_street: address.address1_street,
                        street1_number: address.address1_number,
                        street2: '',
                        city: address.city,
                        zip: address.zip,
                        country: address.country_name,
                    },
                },
            });

            if (response !== 'success') {
                return;
            }

            this.settings.shops[this.selectedShopDomain].returnAddress = address;
        },
        async setDefaultPaymentMethod(method: string | undefined) {
            if (method) {
                const response = await request<ShopSettings>({
                    method: 'post',
                    route: setDefaultPaymentMethodRoute,
                    body: {
                        default: method,
                    },
                });
                if (!response) return;
                this.settings.shops[this.selectedShopDomain] = response;
            }
        },
        async setDefaultAffiliatePayoutMethod(method: string | undefined) {
            if (method) {
                const response = await request<SettingsType>({
                    method: 'post',
                    route: setDefaultAffiliatePayoutMethodRoute,
                    body: {
                        default: method,
                    },
                });
                if (!response) return;
                this.settings = response;
            }
        },
        async requestStripeSetupIntent(id: string): Promise<{ confirmed: boolean; clientSecret?: string } | undefined> {
            return await request<{ confirmed: boolean; clientSecret?: string }>({
                method: 'post',
                route: requestStripeSetupIntentRoute,
                body: {
                    payment_method_id: id,
                },
            });
        },
        async setStripePaymentID(id: string): Promise<any> {
            return await request<any>({
                method: 'post',
                route: setStripePaymentIDRoute,
                body: {
                    payment_method_id: id,
                },
            });
        },
        async retryPayment(orderId: string | null = null): Promise<boolean> {
            const response = await request<string>({
                method: 'get',
                route: retryPaymentRoute,
                body: {
                    ...(orderId ? { orderId: orderId } : null),
                },
            });
            return response === 'OK';
        },
        async setLocalization() {
            await request<void>({
                method: 'patch',
                route: setLocalizationRoute,
                body: {
                    preferredLanguage: this.settings.preferredLanguage,
                    preferredUnitSystem: this.settings.preferredUnitSystem,
                    preferredShoeSizeSystem: this.settings.preferredShoeSizeSystem,
                },
            });
            const langStore = useProductLanguageStore();
            langStore.setLocale(this.settings.preferredLanguage, this.settings.preferredUnitSystem, this.settings.preferredShoeSizeSystem);
        },
        async setShopLocalization(shopDomain?: string) {
            if (!shopDomain) shopDomain = this.selectedShopDomain;
            if (!shopDomain) return;
            const shop = this.settings.shops[shopDomain];
            await request<void>({
                method: 'patch',
                route: setShopLocalizationRoute + '?shopDomain=' + shop.shop_domain,
                body: {
                    productLanguage: shop.productLanguage,
                    productUnitSystem: shop.productUnitSystem,
                    productShoeSizeSystem: shop.productShoeSizeSystem,
                },
            });
        },
        async setNotificationSettings(failedPayment: boolean, personalizedDesign: boolean) {
            const response = await request<ShopSettings>({
                method: 'post',
                route: setNotificationRoute,
                body: {
                    failed_payment: failedPayment,
                    personalized_design: personalizedDesign,
                },
            });
            if (!response) return;
            this.settings.shops[this.selectedShopDomain] = response;
        },
        async setOnboardingFlowCompleted(value: boolean) {
            const shop = this.selectedShop;
            if (!shop) return;
            return request<SettingsType>({
                method: 'patch',
                route: setOnboardingFlowCompletedRoute + value.toString(),
            }).then(() => {
                shop.onboardingFlowCompleted = value;
            });
        },
        async setNewsletterAccepted(value: boolean) {
            return request<SettingsType>({
                method: 'patch',
                route: setNewsletterAcceptedRoute + value.toString(),
            }).then(() => {
                this.settings = {
                    ...this.settings,
                    newsletterAccepted: value,
                };
            });
        },
        async setAutoAvailabilityUpdate() {
            const shop = this.selectedShop;
            if (!shop) return;
            return request<SettingsType>({
                method: 'patch',
                route: autoAvailabilityUpdateRoute + '/' + shop.autoAvailabilityUpdate,
            }).then(() => {});
        },
        async generateApiToken() {
            const shop = this.selectedShop;
            if (!shop) return;
            return request<string>({
                method: 'post',
                route: generateApiTokenRoute,
            }).then(response => {
                shop.apiToken = response;
            });
        },
        async updateIntegrationApiInfos() {
            const shop = this.selectedShop;
            if (!shop) return;
            return request<string>({
                method: 'patch',
                route: updateIntegrationApiInfosRoute,
                body: shop.integrationApiInfos,
            });
        },
        async toggleShopDisconnect() {
            const shop = this.selectedShop;
            if (!shop) return;
            const result = await request<Date | undefined>({
                method: 'patch',
                route: disconnectShopRoute + '/' + (shop.disconnectedAt ? false : true),
            });
            shop.disconnectedAt = result;
        },
    },
    getters: {
        agbAccepted: state => {
            return state.settings.agbAccepted;
        },
        isSettingsLoaded: state => {
            return !!state.settings.email;
        },
        connectedShopDomains: state => {
            return state.settings.shopDomains
                .filter(shopDomain => state.settings.shops[shopDomain]?.isInstalled)
                .map(s => ({ value: s, text: s.replace('.myshopify.com', '') }));
        },
        onboardedShopDomains: state => {
            return state.settings.shopDomains
                .filter(shopDomain => state.settings.shops[shopDomain]?.onboardingFlowCompleted)
                .map(s => ({ value: s, text: s.replace('.myshopify.com', '') }));
        },
        availableShopDomains: state => {
            return state.settings.shopDomains
                .filter(shopDomain => {
                    const shop = state.settings.shops[shopDomain] ?? undefined;
                    return shop?.isInstalled && shop?.onboardingFlowCompleted;
                })
                .map(s => ({ value: s, text: s.replace('.myshopify.com', '') }));
        },
        selectedShop: state => {
            return state.settings.shops[state.selectedShopDomain ?? ''] ?? undefined;
        },
    },
});
