const input = {
    text: 'Text',
    password: 'Passwort',
    email: 'Email',
    number: 'Nummer',
    url: 'Link',
    tel: 'Telefon',
    search: 'Suche',
    date: 'Datum',
    datetime: 'Zeitpunkt',
    'datetime-local': 'Zeitpunkt Lokalzeit',
    month: 'Monat',
    week: 'Woche',
    time: 'Zeit',
    range: 'Skala',
    color: 'Farbe',
};

export default input;
