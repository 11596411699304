const categories = {
    MAN_TSHIRT: 'T-shirts Unisexe/Hommes',
    MAN_HOODIES: 'Sweats à capuche Hommes',
    MAN_SWEATSHIRT: 'Sweat-shirts Hommes',
    MAN_LONGLEEVES: 'Manches longues Hommes',
    MAN_TANKTOP: 'Débardeurs Hommes',
    TROUSERS_UNDERWEAR: 'Pantalons & Sous-vêtements',
    WOMAN_TSHIRT: 'T-shirts Femmes',
    WOMAN_TANKTOP: 'Débardeurs Femmes',
    WOMAN_SWEATSHIRT: 'Sweat-shirts Femmes',
    KINDER_TSHIRT: 'T-shirts Enfants',
    OTHER: 'Autres articles',
    MUG: 'Tasses & Verres',
    HOME: 'Maison & Décoration',

    Frauen: 'Femmes',
    'Unisex / Männer': 'Unisexe / Hommes',
    Sonstiges: 'Autres articles',
    Kinder: 'Enfants',
    'Home & Living': 'Maison & Décoration',
};

export default categories;
