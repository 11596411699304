const AFFILIATION_STORAGE_KEY = 'affiliation';
const AFFILIATION_TIMEOUT_IN_MS = 1000 * 60 * 60 * 24 * 7; //7 days

export function useAffiliation() {
    const setAffiliation = (id: string) => {
        localStorage.setItem(
            AFFILIATION_STORAGE_KEY,
            JSON.stringify({
                id: id,
                addedAt: Date.now(),
            }),
        );
    };

    const getCurrentAffiliation = () => {
        const string = localStorage.getItem(AFFILIATION_STORAGE_KEY);
        if (!string) return null;

        const affiliation = JSON.parse(string) as Affiliation;
        if (!affiliation) return null;

        //Check affiliation timeout
        if (affiliation.addedAt + AFFILIATION_TIMEOUT_IN_MS < Date.now()) {
            clearAffiliation();
            return null;
        }
        return affiliation;
    };

    const clearAffiliation = () => {
        localStorage.removeItem(AFFILIATION_STORAGE_KEY);
    };

    return {
        setAffiliation,
        getCurrentAffiliation,
        clearAffiliation,
    };
}

interface Affiliation {
    id: string;
    addedAt: number;
}
