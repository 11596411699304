import Vue, { watch } from 'vue';
import VueI18n from 'vue-i18n';
import { DateTime } from 'luxon';
import { messages } from '@/assets/languages';
import loadLocale from '@/plugins/vee-validate3';

Vue.use(VueI18n);

const getInitialLocale = () => {
    let locale = 'de';
    const sessionLocale = sessionStorage.getItem('locale');

    if (sessionLocale) locale = sessionLocale;
    else {
        const browserLocale = navigator.language;
        const browserLanguage = browserLocale.substring(0, 2);
        if (browserLanguage == 'de' || browserLanguage == 'fr') locale = browserLanguage;
        else locale = 'en';
        sessionStorage.setItem('locale', locale);
    }
    return locale;
};

const i18n = new VueI18n({
    locale: getInitialLocale(),
    fallbackLocale: 'de',
    messages,
    numberFormats: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
    },
});

loadLocale(i18n.locale);

export function useI18n() {
    const changeLanguage = (language: string) => {
        loadLocale(language);
        i18n.locale = language;
        localStorage.setItem('localeLanguage', language);
        document.title = translate('title').toString();
    };

    return {
        changeLanguage,
    };
}

watch(
    () => i18n.locale,
    language => {
        document.documentElement.lang = language;
    },
    { immediate: true },
);

export function translate(identifier: string, values?: any[] | { [key: string]: any }) {
    return i18n.t(identifier, values).toString();
}

export function numberFormat(value: number) {
    return i18n.n(value);
}
export function formatDate(date: Date, format: string) {
    return DateTime.fromJSDate(new Date(date)).toFormat(format, { locale: i18n.locale });
}

document.title = translate('title').toString();

export default i18n;
