import input from './input';
import button from './button';
import app from './app';
import categories from './categories';

const english = {
    title: 'MarketPrint',
    button: button,
    input: input,
    app: app,
    categories: categories,
};

export default english;
