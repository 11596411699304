import { defineStore } from 'pinia';
import { BaseProduct } from '@/types/backend/productType';
import { Language, ShoeSizeSystem, UnitSystem } from '@/types/frontend/enums/languageEnums';

interface ProductLanguageStore {
    language: Language;
    unitSystem: UnitSystem;
    shoeSystem: ShoeSizeSystem;
    subscriptions: Subscription[];
}

interface Locale {
    language: Language;
    unitSystem: UnitSystem;
    shoeSystem: ShoeSizeSystem;
}

interface Subscription {
    id: string;
    callback: (oldLocale: Locale, newLocale: Locale) => void;
}

const translation = (property: string, language: string, product?: BaseProduct): string => {
    if (!product) return '';
    //@ts-ignore
    if (!product.translations || !product.translations[language]) return product[property] ?? '';
    //@ts-ignore
    return product.translations[language][property] ?? product[property] ?? '';
};

export const useProductLanguageStore = defineStore('productLanguage', {
    state: (): ProductLanguageStore => {
        return {
            language: Language.EN,
            unitSystem: UnitSystem.IMPERIAL,
            shoeSystem: ShoeSizeSystem.EU,
            subscriptions: [],
        };
    },
    actions: {
        async setLocale(language?: Language, unitSystem?: UnitSystem, shoeSystem?: ShoeSizeSystem) {
            const oldLocale = {
                language: this.language,
                unitSystem: this.unitSystem,
                shoeSystem: this.shoeSystem,
            };
            this.language = language ?? this.language;
            this.unitSystem = unitSystem ?? this.unitSystem;
            this.shoeSystem = shoeSystem ?? this.shoeSystem;
            for (const subscription of this.subscriptions) {
                subscription.callback(oldLocale, {
                    language: this.language,
                    unitSystem: this.unitSystem,
                    shoeSystem: this.shoeSystem,
                });
            }
        },
        async subscribe(id: string, callback: (oldLocale: Locale, newLocale: Locale) => void) {
            this.subscriptions.push({ id, callback });
        },
        async unsubscribe(id: string) {
            this.subscriptions = this.subscriptions.filter(s => s.id !== id);
        },
    },
    getters: {
        title(state): (product?: BaseProduct, language?: Language) => string {
            return (product?: BaseProduct, language?: Language): string => {
                return translation('title', language ?? state.language, product);
            };
        },
        descriptionLong(state): (product?: BaseProduct, language?: Language) => string {
            return (product?: BaseProduct, language?: Language): string => {
                return translation('description_long', language ?? state.language, product);
            };
        },
        description(state): (product?: BaseProduct, language?: Language) => string {
            return (product?: BaseProduct, language?: Language): string => {
                return translation('description', language ?? state.language, product);
            };
        },
        sizeTable(state): (product?: BaseProduct, language?: Language, unitSystem?: UnitSystem) => string {
            return (product?: BaseProduct, language?: Language): string => {
                const translatedTable = translation('size_table', language ?? state.language, product);
                const transformation = sizeTableTransformation[state.unitSystem];
                if (transformation) return transformation(translatedTable);
                return translatedTable;
            };
        },
        infoText(state): (product?: BaseProduct, language?: Language) => string {
            return (product?: BaseProduct, language?: Language): string => {
                return translation('infoText', language ?? state.language, product);
            };
        },
        designNote(state): (product?: BaseProduct, language?: Language) => string | undefined {
            return (product?: BaseProduct, language?: Language): string => {
                return translation('designNote', language ?? state.language, product);
            };
        },
        option(state): (option: string, language?: Language, unitSystem?: UnitSystem, shoeSystem?: ShoeSizeSystem) => string {
            return (option: string, language?: Language, unitSystem?: UnitSystem, shoeSystem?: ShoeSizeSystem): string => {
                const languageMapping = OptionTranslations[language ?? state.language];
                const unitMapping = UnitLocalizations[unitSystem ?? state.unitSystem];
                const shoeMapping = ShoeSizeLocalization[shoeSystem ?? state.shoeSystem];

                const locLanguage = languageMapping?.[option];
                if (locLanguage) return locLanguage;

                const locUnit = unitMapping?.[option];
                if (locUnit) return locUnit;

                const locShoe = shoeMapping?.[option];
                if (locShoe) return locShoe;

                return option;
            };
        },
    },
});

const sizeTableTransformation: { [key: string]: ((table: string) => string) | undefined } = {
    [UnitSystem.METRIC]: undefined,
    [UnitSystem.IMPERIAL]: (table: string) => {
        function convertCmToInches(cm: number) {
            const inches = cm / 2.54;
            const roundedInches = Math.round(inches * 2) / 2;
            return roundedInches % 1 === 0 ? roundedInches.toString() : roundedInches.toFixed(1);
        }

        // Convert general measurements, but exclude percentages and numbers within style attributes
        let updatedString = table.replace(/\b(\d{1,3}(?:[.,]\d{1,2})?)\b(?!%)(?=(<\/td>|<\/th>))/g, match => {
            // Replace comma with dot for consistent parsing
            const cmValue = parseFloat(match.replace(',', '.'));
            return convertCmToInches(cmValue);
        });

        // Convert "+/- 3cm" specifically
        updatedString = updatedString.replace(/\+\/- (\d{1,3}(?:[.,]\d{1,2})?)cm/g, (match, p1) => {
            // Replace comma with dot for consistent parsing
            const cmValue = parseFloat(p1.replace(',', '.'));
            const inches = convertCmToInches(cmValue);
            return `+/- ${inches} in`;
        });

        return updatedString;
    },
};

const OptionNameTranslations: { [key: string]: { [key: string]: string } } = {
    [Language.EN]: {
        Größe: 'Size',
        Farbe: 'Color',
        Anzahl: 'Quantity',
        Art: 'Type',
        Duft: 'Scent',
        Form: 'Shape',
    },
    [Language.DE]: {
        Größe: 'Größe',
        Farbe: 'Farbe',
        Anzahl: 'Anzahl',
        Art: 'Art',
        Duft: 'Duft',
        Form: 'Form',
    },
    [Language.FR]: {
        Größe: 'Taille',
        Farbe: 'Couleur',
        Anzahl: 'Quantité',
        Art: 'Type',
        Duft: 'Parfum',
        Form: 'Forme',
    },
};

const OptionTranslations: { [key: string]: { [key: string]: string } } = {
    [Language.EN]: {
        Anthrazit: 'Anthracite',
        'Arctic White': 'Arctic White',
        Babyblau: 'Baby Blue',
        Beige: 'Beige',
        Black: 'Black',
        Blau: 'Blue',
        'Bottle Green': 'Bottle Green',
        'Bright Red': 'Bright Red',
        Burgunder: 'Burgundy',
        Burgundy: 'Burgundy',
        Butter: 'Butter',
        COLOR: 'COLOR',
        'Caramel Toffee': 'Caramel Toffee',
        'Cream Heather Grey': 'Cream Heather Grey',
        'Deep Black': 'Deep Black',
        'Dusky Pink': 'Dusky Pink',
        Edelstahl: 'Stainless Steel',
        'Fire Red': 'Fire Red',
        'French Navy': 'French Navy',
        Fuchsia: 'Fuchsia',
        Geldbgold: 'Golden Yellow',
        Gelbgold: 'Golden Yellow',
        Gold: 'Gold',
        'Golden Yellow': 'Golden Yellow',
        Grün: 'Green',
        'Heather Grey': 'Heather Grey',
        Khaki: 'Khaki',
        Lila: 'Purple',
        Melange: 'Melange',
        Natural: 'Natural',
        'Natural Raw': 'Natural Raw',
        Navy: 'Navy',
        'Navy Blue': 'Navy Blue',
        'New French Navy': 'New French Navy',
        Olive: 'Olive',
        Orange: 'Orange',
        'Orange Crush': 'Orange Crush',
        'Oxford Navy': 'Oxford Navy',
        'Pacific Grey': 'Pacific Grey',
        Pink: 'Pink',
        Purple: 'Purple',
        Raspberry: 'Raspberry',
        Rosa: 'Pink',
        'Rose Wein': 'Rose Wine',
        Roségold: 'Rose Gold',
        Rot: 'Red',
        Rotwein: 'Red Wine',
        'Royal Blau': 'Royal Blue',
        'Royal Blue': 'Royal Blue',
        Sand: 'Sand',
        Schiefergrau: 'Slate Grey',
        Schwarz: 'Black',
        'Schwarz-Gold': 'Black Gold',
        'Schwarz-Silber': 'Black Silver',
        'Signal Orange': 'Signal Orange',
        'Signal Yellow': 'Signal Yellow',
        Silber: 'Silver',
        'Sky Blue': 'Sky Blue',
        Sorbet: 'Sorbet',
        'Spectra Yellow': 'Spectra Yellow',
        'Sport Grey': 'Sport Grey',
        Standard: 'Standard',
        'Steel Grey': 'Steel Grey',
        Taupe: 'Taupe',
        Transparent: 'Transparent',
        'Varsity Green': 'Varsity Green',
        Weinrot: 'Wine Red',
        Weiß: 'White',
        'Weiß/Silber': 'White/Silver',
        Weißwein: 'White Wine',
        caramel: 'Caramel',
        satiniert: 'Satin',
        schwarz: 'Black',
        sereneblue: 'Serene Blue',
        'Washed Grey': 'Washed Grey',
        'Washed Black': 'Washed Black',
        'stem green': 'Stem Green',
        'Black Lila': 'Black Lila',
        'Dark Brown': 'Dark Brown',
        Lilac: 'Lilac',
        'Melange Light Gray': 'Melange Light Gray',
        'Melange Light Lila': 'Melange Light Lilac',
        'Melange Medium Lila': 'Melange Medium Lilac',
        'Petrol Blue': 'Petrol Blue',
        'Purple Violet': 'Purple Violet',
        'Smoke Gray': 'Smoke Gray',
        'TIE DYE Black Lila': 'TIE DYE Black Lila',
        '10 Sticker': '10 Stickers',
        Hase: 'Rabbit',
        Herz: 'Heart',
        Rechteck: 'Rectangle',
        Unisex: 'Unisex',
        'usw.': 'etc.',
        Dunkelgrün: 'Dark Green',
        Graublau: 'Grey Blue',
    },
    [Language.DE]: {
        Anthrazit: 'Anthrazit',
        'Arctic White': 'Arctic White',
        Babyblau: 'Babyblau',
        Beige: 'Beige',
        Black: 'Schwarz',
        Blau: 'Blau',
        'Bottle Green': 'Bottle Green',
        'Bright Red': 'Bright Red',
        Burgunder: 'Burgunder',
        Burgundy: 'Burgunder',
        Butter: 'Butter',
        COLOR: 'FARBE',
        'Caramel Toffee': 'Caramel Toffee',
        'Cream Heather Grey': 'Cream Heather Grey',
        'Deep Black': 'Deep Black',
        'Dusky Pink': 'Dusky Pink',
        Edelstahl: 'Edelstahl',
        'Fire Red': 'Fire Red',
        'French Navy': 'French Navy',
        Fuchsia: 'Fuchsia',
        Geldbgold: 'Geldbgold',
        Gelbgold: 'Gelbgold',
        Gold: 'Gold',
        'Golden Yellow': 'Golden Yellow',
        Grün: 'Grün',
        'Heather Grey': 'Heather Grey',
        Khaki: 'Khaki',
        Lila: 'Lila',
        Melange: 'Melange',
        Natural: 'Natural',
        'Natural Raw': 'Natural Raw',
        Navy: 'Navy',
        'Navy Blue': 'Navy Blue',
        'New French Navy': 'New French Navy',
        Olive: 'Olive',
        Orange: 'Orange',
        'Orange Crush': 'Orange Crush',
        'Oxford Navy': 'Oxford Navy',
        'Pacific Grey': 'Pacific Grey',
        Pink: 'Pink',
        Purple: 'Purple',
        Raspberry: 'Raspberry',
        Rosa: 'Rosa',
        'Rose Wein': 'Rose Wein',
        Roségold: 'Roségold',
        Rot: 'Rot',
        Rotwein: 'Rotwein',
        'Royal Blau': 'Royal Blau',
        'Royal Blue': 'Royal Blue',
        Sand: 'Sand',
        Schiefergrau: 'Schiefergrau',
        Schwarz: 'Schwarz',
        'Schwarz-Gold': 'Schwarz-Gold',
        'Schwarz-Silber': 'Schwarz-Silber',
        'Signal Orange': 'Signal Orange',
        'Signal Yellow': 'Signal Yellow',
        Silber: 'Silber',
        'Sky Blue': 'Sky Blue',
        Sorbet: 'Sorbet',
        'Spectra Yellow': 'Spectra Yellow',
        'Sport Grey': 'Sport Grey',
        Standard: 'Standard',
        'Steel Grey': 'Steel Grey',
        Taupe: 'Taupe',
        Transparent: 'Transparent',
        'Varsity Green': 'Varsity Green',
        Weinrot: 'Weinrot',
        Weiß: 'Weiß',
        'Weiß/Silber': 'Weiß/Silber',
        Weißwein: 'Weißwein',
        caramel: 'caramel',
        satiniert: 'satiniert',
        schwarz: 'schwarz',
        sereneblue: 'sereneblue',
        'Washed Grey': 'Washed Grey',
        'Washed Black': 'Washed Black',
        'stem green': 'stem green',
        'Black Lila': 'Black Lila',
        'Dark Brown': 'Dark Brown',
        Lilac: 'Lilac',
        'Melange Light Gray': 'Melange Light Gray',
        'Melange Light Lila': 'Melange Light Lila',
        'Melange Medium Lila': 'Melange Medium Lila',
        'Petrol Blue': 'Petrol Blue',
        'Purple Violet': 'Purple Violet',
        'Smoke Gray': 'Smoke Gray',
        'TIE DYE Black Lila': 'TIE DYE Black Lila',
        '10 Sticker': '10 Sticker',
        Hase: 'Hase',
        Herz: 'Herz',
        Rechteck: 'Rechteck',
        Unisex: 'Unisex',
        'usw.': 'usw.',
        Dunkelgrün: 'Dunkelgrün',
        Graublau: 'Graublau',
    },
    [Language.FR]: {
        Anthrazit: 'Anthracite',
        'Arctic White': 'Blanc Arctique',
        Babyblau: 'Bleu Bébé',
        Beige: 'Beige',
        Black: 'Noir',
        Blau: 'Bleu',
        'Bottle Green': 'Vert Bouteille',
        'Bright Red': 'Rouge Vif',
        Burgunder: 'Bourgogne',
        Burgundy: 'Bourgogne',
        Butter: 'Beurre',
        COLOR: 'COULEUR',
        'Caramel Toffee': 'Caramel Toffee',
        'Cream Heather Grey': 'Crème Gris Chiné',
        'Deep Black': 'Noir Profond',
        'Dusky Pink': 'Rose Poudré',
        Edelstahl: 'Acier Inoxydable',
        'Fire Red': 'Rouge Feu',
        'French Navy': 'Marine Française',
        Fuchsia: 'Fuchsia',
        Geldbgold: 'Jaune Doré',
        Gelbgold: 'Jaune Doré',
        Gold: 'Or',
        'Golden Yellow': 'Jaune Doré',
        Grün: 'Vert',
        'Heather Grey': 'Gris Chiné',
        Khaki: 'Kaki',
        Lila: 'Violet',
        Melange: 'Mélange',
        Natural: 'Naturel',
        'Natural Raw': 'Naturel Brut',
        Navy: 'Marine',
        'Navy Blue': 'Bleu Marine',
        'New French Navy': 'Nouvelle Marine Française',
        Olive: 'Olive',
        Orange: 'Orange',
        'Orange Crush': 'Orange Crush',
        'Oxford Navy': 'Marine Oxford',
        'Pacific Grey': 'Gris Pacifique',
        Pink: 'Rose',
        Purple: 'Violet',
        Raspberry: 'Framboise',
        Rosa: 'Rose',
        'Rose Wein': 'Vin Rosé',
        Roségold: 'Or Rose',
        Rot: 'Rouge',
        Rotwein: 'Vin Rouge',
        'Royal Blau': 'Bleu Royal',
        'Royal Blue': 'Bleu Royal',
        Sand: 'Sable',
        Schiefergrau: 'Gris Ardoise',
        Schwarz: 'Noir',
        'Schwarz-Gold': 'Noir Or',
        'Schwarz-Silber': 'Noir Argent',
        'Signal Orange': 'Orange Signal',
        'Signal Yellow': 'Jaune Signal',
        Silber: 'Argent',
        'Sky Blue': 'Bleu Ciel',
        Sorbet: 'Sorbet',
        'Spectra Yellow': 'Jaune Spectra',
        'Sport Grey': 'Gris Sport',
        Standard: 'Standard',
        'Steel Grey': 'Gris Acier',
        Taupe: 'Taupe',
        Transparent: 'Transparent',
        'Varsity Green': 'Vert Varsity',
        Weinrot: 'Rouge Vin',
        Weiß: 'Blanc',
        'Weiß/Silber': 'Blanc/Argent',
        Weißwein: 'Vin Blanc',
        caramel: 'Caramel',
        satiniert: 'Satiné',
        schwarz: 'Noir',
        sereneblue: 'Bleu Serein',
        'Washed Grey': 'Gris Lavé',
        'Washed Black': 'Noir Lavé',
        'stem green': 'Tige Verte',
        'Black Lila': 'Noir Lila',
        'Dark Brown': 'Marron Foncé',
        Lilac: 'Lilas',
        'Melange Light Gray': 'Gris Clair Chiné',
        'Melange Light Lila': 'Lilas Clair Chiné',
        'Melange Medium Lila': 'Lilas Moyen Chiné',
        'Petrol Blue': 'Bleu Pétrole',
        'Purple Violet': 'Violet Violet',
        'Smoke Gray': 'Gris Fumée',
        'TIE DYE Black Lila': 'TIE DYE Noir Lila',
        '10 Sticker': '10 Autocollants',
        Hase: 'Lapin',
        Herz: 'Cœur',
        Rechteck: 'Rectangle',
        Unisex: 'Unisexe',
        'usw.': 'etc.',
        Dunkelgrün: 'Vert Foncé',
        Graublau: 'Bleu Gris',
    },
};

const UnitLocalizations: { [key: string]: { [key: string]: string } } = {
    [UnitSystem.METRIC]: {
        '10cm x 10cm': '10cm x 10cm',
        '120cm x 120cm': '120cm x 120cm',
        '120cm x 90cm': '120cm x 90cm',
        '20cm x 15cm': '20cm x 15cm',
        '30cm x 30cm': '30cm x 30cm',
        '30cm x 40cm': '30cm x 40cm',
        '40cm x 30cm': '40cm x 30cm',
        '40cm x 40cm': '40cm x 40cm',
        '45cm x 60cm': '45cm x 60cm',
        '60cm x 60cm': '60cm x 60cm',
        '60cm x 80cm': '60cm x 80cm',
        '66cm x 44cm': '66cm x 44cm',
        '70cm x 180cm': '70cm x 180cm',
        '70cm x 70cm': '70cm x 70cm',
        '80cm x 60cm': '80cm x 60cm',
        '90cm x 120cm': '90cm x 120cm',
        '90cm x 90cm': '90cm x 90cm',
        '100ml': '100ml',
        '1200ml': '1200ml',
        '178mmx127mm': '178mmx127mm',
        '200ml': '200ml',
        '203mmx254mm': '203mmx254mm',
        '300ml': '300ml',
        '30x30': '30x30',
        '330ml': '330ml',
        '350 ml': '350 ml',
        '380ml': '380ml',
        '470ml': '470ml',
        '500ml': '500ml',
        '660ml': '660ml',
        '750ml': '750ml',
        '75x90': '75x90',
        '80mm': '80mm',
        '0,75': '0,75',
    },
    [UnitSystem.IMPERIAL]: {
        '10cm x 10cm': '3.9" x 3.9"',
        '120cm x 120cm': '47.2" x 47.2"',
        '120cm x 90cm': '47.2" x 35.4"',
        '20cm x 15cm': '7.8" x 5.9"',
        '30cm x 30cm': '11.8" x 11.8"',
        '30cm x 40cm': '11.8" x 15.7"',
        '40cm x 30cm': '15.7" x 11.8"',
        '40cm x 40cm': '15.7" x 15.7"',
        '45cm x 60cm': '17.7" x 23.6"',
        '60cm x 60cm': '23.6" x 23.6"',
        '60cm x 80cm': '23.6" x 31.5"',
        '66cm x 44cm': '25.9" x 17.3"',
        '70cm x 180cm': '27.5" x 70.8"',
        '70cm x 70cm': '27.5" x 27.5"',
        '80cm x 60cm': '31.5" x 23.6"',
        '90cm x 120cm': '35.4" x 47.2"',
        '90cm x 90cm': '35.4" x 35.4"',

        '100ml': '3.5oz',
        '1200ml': '40oz',
        '178mmx127mm': '7" x 5"',
        '200ml': '7oz',
        '203mmx254mm': '8" x 10"',
        '300ml': '10oz',
        '30x30': '11.8" x 11.8"',
        '330ml': '11oz',
        '350 ml': '12oz',
        '380ml': '13oz',
        '470ml': '16oz',
        '500ml': '17oz',
        '660ml': '22oz',
        '750ml': '25oz',
        '75x90': '29.5" x 35.4"',
        '80mm': '3.2"',
        '0,75': '25oz',
    },
};

const ShoeSizeLocalization: { [key: string]: { [key: string]: string } } = {
    [ShoeSizeSystem.EU]: {
        '35-38': '35-38',
        '39-42': '39-42',
        '36': '36',
        '37': '37',
        '38': '38',
        '39': '39',
        '40': '40',
        '41': '41',
        '42': '42',
        '43': '43',
        '44': '44',
        '45': '45',
        '46': '46',
    },
    [ShoeSizeSystem.US]: {
        '35-38': '4-7.5',
        '39-42': '8-11',
        '36': '5.5',
        '37': '6.5',
        '38': '7.5',
        '39': '8.5',
        '40': '9.5',
        '41': '10.5',
        '42': '11.5',
        '43': '12.5',
        '44': '13.5',
        '45': '14.5',
        '46': '15.5',
    },
    [ShoeSizeSystem.UK]: {
        '35-38': '2-5.5',
        '39-42': '6-9.5',
        '36': '3.5',
        '37': '4.5',
        '38': '5.5',
        '39': '6.5',
        '40': '7.5',
        '41': '8.5',
        '42': '9.5',
        '43': '10.5',
        '44': '11.5',
        '45': '12.5',
        '46': '13.5',
    },
};
