const retryInit = (func: Function, times?: number, delay?: number) => {
    if (!func) return;
    return new Promise((resolve, reject) => {
        let error: any;

        const attempt = async () => {
            if (times && times == 0) {
                reject(error);
            } else {
                if (!func) reject('No Function');
                try {
                    const result = await func();
                    resolve(result);
                } catch (e: any) {
                    if (times) times--;
                    error = e;
                    setTimeout(() => attempt(), delay ?? 500);
                }
            }
        };

        attempt();
    });
};

export default retryInit;
