const input = {
    text: 'Texte',
    password: 'Mot de passe',
    email: 'Email',
    number: 'Nombre',
    url: 'Lien',
    tel: 'Téléphone',
    search: 'Recherche',
    date: 'Date',
    datetime: 'Heure',
    'datetime-local': 'Heure locale',
    month: 'Mois',
    week: 'Semaine',
    time: 'Temps',
    range: 'Échelle',
    color: 'Couleur',
};

export default input;
