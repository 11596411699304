import thisVue from '@/main';
import retryInit from '@/composables/useInit';
import { ToastOptions } from '@/types/frontend/bootstrapFunctionTypes';

export let toastController = thisVue?.$bvToast;

let init = false;

export function useToast() {
    const initToastController = () => {
        if (init) return;
        toastController = thisVue?.$bvToast;
        if (!toastController) throw Error('No Toast controller found');
        init = true;
    };

    retryInit(initToastController, 20);
}

const popToast = (params: ToastOptions) => {
    if (!toastController) useToast();

    const {
        content,
        title,
        variant,
        noCloseButton,
        autoHideDelay,
        headerClass,
        bodyClass,
        toastClass,
        id,
        noFade,
        noAutoHide,
        solid,
        noHoverPause,
        toaster,
        isStatus,
    } = params;

    toastController?.toast(content, {
        title: title ?? '',
        variant: variant,
        noCloseButton: noCloseButton,
        headerClass: headerClass,
        autoHideDelay: autoHideDelay,
        bodyClass: bodyClass,
        toastClass: [toastClass, '[insert custom toast class of project]'],
        toaster: toaster ?? 'b-toaster-bottom-center',
        id: id,
        noFade: noFade,
        noAutoHide: noAutoHide,
        solid: solid,
        noHoverPause: noHoverPause,
        isStatus: isStatus,
    });
};

export { popToast };
